export default {
  "ie9": { 
    "browserName": "internet explorer"
  , "version": "9.0" 
  , "platform": "Windows 7" 
  , "_name": "ie"
  , "_version": "9"
  , "_os": "windows"
  }
, "ie11": { 
    "browserName": "internet explorer"
  , "version": "11.0" 
  , "platform": "Windows 7"
  , "_name": "ie"
  , "_version": "11"
  , "_os": "windows"
  }
, "edge": { 
    "browserName": "MicrosoftEdge"
  , "platform": "Windows 10"
  , "_name": "ie"
  , "_os": "windows"
  }
, "firefox": { 
    "browserName": "firefox"
  , "platform": "WINDOWS" 
  , "_name": "firefox"
  , "_os": "windows"
  }
, "chrome": { 
    "browserName": "chrome"
  , "platform": "WINDOWS" 
  , "_name": "chrome"
  , "_os": "windows"
  }
, "android": { 
    "browserName": "android"
  , "device": "Google Nexus 5"
  , "platform": "ANDROID"
  , "_name": "android"
  , "_os": "nexus"
  , "_os_version": "5"
  }
, "iphone": { 
    "browserName": "iPhone"
  , "device": "iPhone 5"
  , "platform": "MAC"  
  , "_name": "safari"
  , "_os": "iphone"
  , "_os_version": "5"
  }
, "opera": { 
    "browserName": "opera"
  , "platform": "WINDOWS"  
  , "_name": "opera"
  , "_os": "windows"
  }
, "safari": { 
    "browserName": "safari"
  , "platform": "MAC"  
  , "_name": "safari"
  , "_os": "osx"
  }
};