import _pure from "utilise/pure";
import _browsers from "./browsers.json";
import _err from "utilise/err";
import _log from "utilise/log";
import _process from "process";
var exports = {};
var process = _process;

const {
  extend,
  str
} = _pure,
      browsers = _browsers,
      err = _err("[popper][saucelabs]"),
      log = _log("[popper][saucelabs]");

exports = {
  browsers,
  connect,
  status,
  parse
};

function connect(wd) {
  const env = process.env,
        key = env.SAUCE_ACCESS_KEY,
        user = env.SAUCE_USERNAME,
        host = "ondemand.saucelabs.com";
  return !user || !key ? (err("Please provide your SauceLabs Credentials"), false) : wd.remote(host, 80, user, key);
}

function status(browser, platform) {
  browser.vm.sauceJobStatus(browser.passed, e => {
    e ? err(e) : log("status updated", platform.uid.bold, str(browser.passed)[browser.passed ? "green" : "red"], str(browser.build).grey);
    browser.vm.quit();
  });
}

function parse(opts) {
  return extend(opts)({
    "tunnel-identifier": process.env.TRAVIS_JOB_NUMBER,
    build: process.env.TRAVIS_BUILD_NUMBER || ~~(Math.random() * 100000000),
    username: process.env.SAUCE_USERNAME,
    accessKey: process.env.SAUCE_ACCESS_KEY
  });
}

export default exports;