import _browsers from "./browsers.json";
import _err from "utilise/err";
import _process from "process";
var exports = {};
var process = _process;

const browsers = _browsers,
      err = _err("[popper][browserstack]");

exports = {
  browsers,
  connect
};

function connect(wd) {
  const env = process.env,
        key = env.BROWSERSTACK_KEY,
        user = env.BROWSERSTACK_USERNAME,
        host = "hub.browserstack.com";
  return !user || !key ? (err("Please provide your BrowserStack Credentials"), false) : wd.remote(host, 80, user, key);
}

export default exports;